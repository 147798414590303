export const websiteSubscriptionTypes = ["Annual - Website", "Lifer - Website", "Lifer - Professional", "Professional"]

export const practitionerWebsiteUrl = (user, practice) => {
  let slug = user.vanitySlug || user.slug
  if (!practice) {
    return `/${slug}`
  }
  const websiteUrl =
    practice.website && websiteSubscriptionTypes.includes(user.subscriptionType) ? practice.website : `/${slug}`

  return websiteUrl
}

// Hit is the practitioner object from Algolia and is snake_case
export const searchPractitionerWebsiteUrl = (hit) => {
  if (hit.custom_domain_host) {
    return `https://${hit.custom_domain_host}`
  }

  let slug = hit.user.vanity_slug || hit.user.slug

  const websiteUrl = hit.website && websiteSubscriptionTypes.includes(hit.subscription_type) ? hit.website : `/${slug}`

  return websiteUrl
}
